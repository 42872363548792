<template>
  <ion-page>
    <ion-content class="ion-padding">
      <ion-row>
        <ion-col>
          <ion-text class="welcome">
            My account. ////////// UNUSED!!!!!!!!!!!!!!!!!!!!
          </ion-text>
        </ion-col>
      </ion-row>
      <ion-row class="margin-5vh-top">
        <ion-col>
          <ion-item
            lines="full"
            button
            @click="openMyModal('editMyProfile')"
          >
            <ion-icon :icon="personOutline" />
            <ion-label>
              <span class="ion-padding-start">Edit My Details</span>
            </ion-label>
          </ion-item>
          <ion-item
            lines="none"
          >
            <ion-icon :icon="shareSocialOutline" />
            <ion-label>
              <span class="ion-padding-start">Share my property</span>
            </ion-label>
            <ion-toggle />
          </ion-item>
          <ion-item
            button
            lines="full"
          >
            <ion-icon :icon="peopleOutline" />
            <ion-label>
              <span class="ion-padding-start">Manage users accesing my property</span>
            </ion-label>
          </ion-item>
          <ion-item
            button
            lines="full"
            @click="openMyModal('myDevices')"
          >
            <ion-icon :icon="hardwareChipOutline" />
            <ion-label>
              <span class="ion-padding-start">All My Devices</span>
            </ion-label>
          </ion-item>
        </ion-col>
      </ion-row>
    </ion-content>
    <ion-footer class="ion-padding-end">
      <ion-toolbar>
        <ion-button
          slot="end"
          color="dark"
          @click="LogOut"
        >
          logout
        </ion-button>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script>
import { modalController } from '@ionic/vue'
import { personOutline, shareSocialOutline, peopleOutline, hardwareChipOutline } from 'ionicons/icons'

import editMyProfileModal from '../components/EditMyProfileModal'
import myDevicesModal from '../components/MyDevicesModal'

export default {
  data () {
    return {
      personOutline,
      shareSocialOutline,
      peopleOutline,
      hardwareChipOutline
    }
  },
  methods: {
    async LogOut () {
      await this.$store
        .dispatch('LogOut')
        .then(() => this.$router.replace('/login'))
    },
    openMyModal (whichModal) {
      let modal
      if (whichModal === 'editMyProfile') {
        modal = editMyProfileModal
      }
      if (whichModal === 'myDevices') {
        modal = myDevicesModal
      }
      return modalController
        .create({
          component: modal,
          cssClass: 'modal welcome-modal',
          swipeToClose: true,
          showBackdrop: false,
          backdropDismiss: true,
          componentProps: {
          // data: {
          // },
          // propsData: {
          //   // user_id: user_id
          // },
          // parent: this
            modalTitle: 'whichModal',
            close: () => this.closeModal()
          }
        })
        .then(m => m.present())
    },
    closeModal () {
      modalController.dismiss()
    }
  }
}
</script>

<style scoped>

</style>
